import {
    aggregations_aggregations_aggregations,
    aggregations_aggregations_aggregations_options,
} from 'src/queries/__generated__/aggregations';
import { createDebug } from 'src/util/debug';

import { FilterEqualTypeInput, ProductAttributeFilterInput } from '../../../../__generated__/globalTypes';

const debug = createDebug('cleanAggregations');

export type ProductFilter = {
    attribute_code: string;
    values: number[];
};

export type MappedAggregations = Map<
    string,
    { label: string; attribute_code: string; options: Map<string, AggregationOption> }
>;

export interface AggregationSelections {
    [index: string]: string[];
}

export type AggregationDisplay = {
    label: string;
    option: AggregationOption;
    attribute_code: string;
};

export type Aggregation = {
    /**
     * The aggregation display name.
     */
    label: string | null;
    /**
     * Attribute code of the aggregation group.
     */
    attribute_code: string;
    /**
     * The number of options in the aggregation group.
     */
    count: number | null;
    /**
     * Array of options for the aggregation.
     */
    options: AggregationOption[];
};

export type AggregationOption = {
    /**
     * The number of items that match the aggregation option.
     */
    count: number | null;
    /**
     * Aggregation option display label.
     */
    label: string | null;
    /**
     * Aggregation label to be displayed to the user
     */
    displayLabel: string;
    /**
     * The internal ID that represents the value of the option.
     */
    value: string;

    swatch_data: {
        __typename: 'ColorSwatchData' | 'ImageSwatchData' | 'TextSwatchData';
        /**
         * Value of swatch item (HEX color code, image link or textual value)
         */
        value: string | null;
    } | null;
};

/**
 * Attributes not suitable for the layered navigation
 */
const excludeList = new Set(['price', 'category_id', 'color']);

export type TagsAggregation = {
    label: string;
    value: string;
};

/**
 * Simple function that checks if the input matches 0 or 1 then returns the correct value
 * @param oldLabel
 */
export function displayLabel(oldLabel) {
    switch (oldLabel) {
        case '1': {
            return 'Yes';
        }
        case '0': {
            return 'No';
        }
        default: {
            return oldLabel;
        }
    }
}

export function fromAggregationsQuery(aggregations: aggregations_aggregations_aggregations[]): Aggregation[] {
    return aggregations
        .filter((x) => {
            // remove things in the exlude list
            if (excludeList.has(x.attribute_code)) return false;
            // if less than 1 option, don't show
            // if (x.options && x.options.length <= 1) return false;
            return true;
        })
        .map((item) => {
            const options = item.options as aggregations_aggregations_aggregations_options[];

            /**
             * If the option set is yes/no, ensure they are in the correct order
             */
            if (options.length === 2) {
                const matchSet = new Set(['1', '0']);
                if (matchSet.has(options[0].label!) && matchSet.has(options[1].label!)) {
                    if (options[0].label! !== '1') {
                        options.reverse();
                    }
                }
            }

            const filteredArray = options.filter((obj) => obj.label === '1' || obj.label === '0');
            const isBooleanArray = filteredArray.length === 2 && options.length === 2;

            return {
                ...item,
                options: options.map((option) => {
                    return {
                        ...option,
                        displayLabel: isBooleanArray ? displayLabel(option.label) : option.label,
                    };
                }),
            };
        });
}

export function selectionsFromSearch(mapped: MappedAggregations, search: string): AggregationSelections {
    debug('selectionsFromSearch for %s', search);
    const selections: AggregationSelections = {};
    const params = new URLSearchParams(search);
    params.forEach((value, key) => {
        if (value) {
            const safeKey = key;
            const matchSet = mapped.get(safeKey);
            if (matchSet) {
                const safe_values = splitOptionValues(value);
                safe_values.forEach((v) => {
                    const lookup = (() => {
                        //Keep comments as a reference point Yes/No values no longer work
                        if (v.toLowerCase() === 'yes') {
                            return '1';
                        }
                        if (v.toLowerCase() === 'no') {
                            return '0';
                        }
                        return v.toLowerCase();
                    })();
                    const matchingOption = matchSet.options.get(lookup);
                    if (matchingOption) {
                        if (!selections[key]) selections[key] = [];
                        selections[key].push(matchingOption.label!);
                    }
                });
            }
        }
    });
    return selections;
}

export function productFiltersFromSearch(
    mapped: MappedAggregations,
    search: string,
    categoryId = '274',
): ProductAttributeFilterInput {
    const selections: ProductAttributeFilterInput = {
        category_id: { eq: categoryId },
    };
    const params = new URLSearchParams(search);

    params.forEach((value, key) => {
        if (value) {
            const safeKey = key;
            const matchSet = mapped.get(safeKey);
            if (matchSet) {
                const safe_values = splitOptionValues(value);
                const filtered: string[] = safe_values
                    .filter((v) => {
                        if (v === 'Yes') {
                            return matchSet.options.has('1');
                        }
                        if (v === 'No') {
                            return matchSet.options.has('0');
                        }
                        return matchSet.options.has(v.toLowerCase());
                    })
                    .map((v) => {
                        if (v === 'Yes') {
                            return matchSet.options.get('1')!.value;
                        }
                        if (v === 'No') {
                            return matchSet.options.get('0')!.value;
                        }
                        return matchSet.options.get(v.toLowerCase())!.value;
                    })
                    .map(String);

                if (filtered.length > 0) {
                    const filter: FilterEqualTypeInput = { in: filtered };
                    selections[safeKey] = filter;
                }
            }
        }
    });

    return selections;
}

export function aggregationsToMap(aggregations: Aggregation[]): MappedAggregations {
    return new Map(
        aggregations.map((agg) => {
            const key = agg.attribute_code;

            const filteredArray = agg.options.filter((obj) => obj.label === '1' || obj.label === '0');
            const isBooleanArray = filteredArray.length === 2 && agg.options.length === 2;

            const data = {
                label: agg.label!,
                attribute_code: agg.attribute_code,
                options: new Map(
                    agg.options.map((opt) => {
                        return [
                            opt.label!.toLowerCase(),
                            {
                                count: opt.count,
                                label: opt.label,
                                displayLabel: isBooleanArray ? displayLabel(opt.label) : opt.label,
                                value: opt.value,
                                swatch_data: opt.swatch_data,
                            },
                        ];
                    }),
                ),
            };
            return [key, data];
        }),
    );
}

export function selectionToValues(mapped: MappedAggregations, selections: AggregationSelections): AggregationDisplay[] {
    const values: AggregationDisplay[] = [];
    Object.keys(selections).forEach((key) => {
        const item = mapped.get(key);
        if (item) {
            selections[key].forEach((value) => {
                const maybe = item.options.get(value.toLowerCase());
                if (maybe) {
                    values.push({
                        attribute_code: item.attribute_code,
                        label: item.label,
                        option: maybe,
                    });
                }
            });
        }
    });
    return values;
}

export function removeSelection(selections: AggregationSelections, code: string, label: string): AggregationSelections {
    const match = selections[code];
    if (match && match.length > 0) {
        const filtered = match.filter((x) => x !== label);
        const next = {
            ...selections,
            [code]: filtered,
        };
        if (filtered.length === 0) {
            delete next[code];
        }
        return next;
    }
    return selections;
}
export function addSelection(selections: AggregationSelections, code: string, label: string): AggregationSelections {
    const match = selections[code];
    if (!match) {
        return {
            ...selections,
            [code]: [label],
        };
    }
    return {
        ...selections,
        [code]: match.concat(label),
    };
}

export function serializeSelectionsToUrl(
    mapped: MappedAggregations,
    selections: AggregationSelections,
    search: string,
): string {
    const url = new URLSearchParams(search);
    /**
     * Prevent page counts from carrying over between selections
     */
    url.delete('page');

    mapped.forEach((value, key) => {
        const matchingSelections = selections[key];

        if (!matchingSelections || (Array.isArray(matchingSelections) && matchingSelections.length === 0)) {
            url.delete(key);
        }
        if (matchingSelections) {
            const next = matchingSelections
                .filter((l) => value.options.has(l.toLowerCase()))
                .map((l) => {
                    const option = value.options.get(l.toLowerCase())!;
                    if (option.label === '1') {
                        return 'Yes';
                    }
                    if (option.label === '0') {
                        return 'No';
                    }
                    return option.label;
                });

            url.set(key, next.join(','));
        }
    });

    return url.toString();
}

export function splitOptionValues(value: string): string[] {
    return String(value).trim().split(',');
}
